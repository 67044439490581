<template>
  <div>
    <modal
      ref="downloadContractTemplateModal"
      @close="reset"
      :title="$t('contractTemplates.downloadContractTemplate')"
    >
      <form
        class="is-hidden"
        ref="downloadingForm"
        method="POST"
        target="_blank"
        :action="`${API}/contracttemplate/${documentId}`"
      >
        <p>initial p</p>
        <input type="hidden" name="token" :value="authToken" />
      </form>
      <datatable
        :single-select="true"
        :size="15"
        :fetch="(p) => $api.fetchContractTemplates(p, 'contractor_contract')"
        :columns="{ templateName: $t('expressions.contractName') }"
        @input="downloadContractTemplate($event)"
        selector
        class="mb-5"
      >
      </datatable>
      <div slot="toolbar"></div>
    </modal>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "download-contract-template-modal",
  props: {},
  data() {
    return {
      documentId: null,
      API: axios.defaults.baseURL,
      authToken: this.$store.getters["auth/getToken"],
    };
  },
  computed: {},
  methods: {
    open() {
      console.log("this.documentId", this.documentId);
      this.$openModal("downloadContractTemplateModal");
    },
    close() {
      this.$closeModal("downloadContractTemplateModal");
    },
    downloadContractTemplate(event) {
      this.documentId = event.id;
      this.$nextTick(() => {
        if (this.$refs.downloadingForm) {
          console.log(this.$refs.downloadingForm.action);
          this.$refs.downloadingForm.submit();
          this.close();
        }
      });
    },
    reset() {
      this.documentId = null;
    },
  },
};
</script>
