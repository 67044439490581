<template>
  <modal
    ref="sendToSignatureModal"
    @create="sendToSignature"
    :disabled="disabled"
    @close="reset"
    :action="$t('common.send')"
    :loading="isSending"
    :title="$t('envelopes.signature.signersModalTitle')"
  >
    <div class="columns">
      <div class="column is-half">
        <h3 class="subtitle is-3">
          {{ $t("envelopes.signature.axioneSide") }}
        </h3>
        <text-field
          :label="$t('common.name')"
          v-model="signers[0].name"
          :inline="false"
          :edit="true"
          required
        >
        </text-field>
        <text-field
          :label="$t('common.email')"
          v-model="signers[0].email"
          :inline="false"
          :edit="true"
          required
          type="email"
        >
        </text-field>
        <text-field
          label="Phone Country Code"
          v-model="signers[0].phoneCountryCode"
          :inline="false"
          :edit="true"
          required
        >
        </text-field>
        <text-field
          :label="$t('common.phone')"
          v-model="signers[0].phoneNumber"
          :inline="false"
          :edit="true"
          required
        >
        </text-field>
        <h4 class="subtitle is-4">
          <span>{{ $t("envelopes.signature.signaturePosition") }}</span>
          <span class="help" :title="$t('envelopes.signature.positionHelper')"
            >?</span
          >
        </h4>
        <div class="tabs is-toggle">
          <ul>
            <li
              :class="{ 'is-active': !signers[0].anchorRequired }"
              @click="signers[0].anchorRequired = false"
            >
              <a><span>Manuel</span></a>
            </li>
            <li
              :class="{ 'is-active': signers[0].anchorRequired }"
              @click="signers[0].anchorRequired = true"
            >
              <a><span>Automatique</span></a>
            </li>
          </ul>
        </div>
        <text-field
          :label="$t('envelopes.signature.anchorString')"
          v-model="signers[0].anchorString"
          v-if="signers[0].anchorRequired"
          :inline="false"
          :required="signers[0].anchorRequired"
          :edit="true"
        >
        </text-field>
      </div>
      <div class="column is-half">
        <h3 class="subtitle is-3">
          {{ $t("envelopes.signature.contractorSide") }}
        </h3>
        <text-field
          :label="$t('common.name')"
          v-model="signers[1].name"
          :inline="false"
          :edit="true"
          required
        >
        </text-field>
        <text-field
          :label="$t('common.email')"
          v-model="signers[1].email"
          :inline="false"
          :edit="true"
          required
          type="email"
        >
        </text-field>
        <text-field
          label="Phone Country Code"
          v-model="signers[1].phoneCountryCode"
          :inline="false"
          :edit="true"
          required
        >
        </text-field>
        <text-field
          :label="$t('common.phone')"
          v-model="signers[1].phoneNumber"
          :inline="false"
          :edit="true"
          required
        >
        </text-field>
        <h4 class="subtitle is-4">
          <span>{{ $t("envelopes.signature.signaturePosition") }}</span>
          <span class="help" :title="$t('envelopes.signature.positionHelper')"
            >?</span
          >
        </h4>
        <div class="tabs is-toggle">
          <ul>
            <li
              :class="{ 'is-active': !signers[1].anchorRequired }"
              @click="signers[1].anchorRequired = false"
            >
              <a><span>Manuel</span></a>
            </li>
            <li
              :class="{ 'is-active': signers[1].anchorRequired }"
              @click="signers[1].anchorRequired = true"
            >
              <a><span>Automatique</span></a>
            </li>
          </ul>
        </div>
        <text-field
          :label="$t('envelopes.signature.anchorString')"
          v-if="signers[1].anchorRequired"
          v-model="signers[1].anchorString"
          :inline="false"
          :required="signers[1].anchorRequired"
          :edit="true"
          :conflict="conflict"
        >
        </text-field>
      </div>
    </div>
  </modal>
</template>
<script>
import axios from "axios";
export default {
  name: "send-to-signature-modal",
  props: {
    envelopeId: [String, Number],
  },
  data() {
    return {
      signers: [
        {
          name: "",
          email: "",
          anchorRequired: false,
          anchorString: "key1",
          phoneCountryCode: "+33",
        },
        {
          name: "",
          email: "",
          anchorRequired: false,
          anchorString: "key2",
          phoneCountryCode: "+33",
        },
      ],
      isSending: false,
    };
  },
  computed: {
    conflict() {
      // Anchor strings should not be the same
      if (
        this.signers[0].anchorRequired &&
        this.signers[1].anchorRequired &&
        this.signers[0].anchorString === this.signers[1].anchorString
      ) {
        return this.$t("envelopes.signature.anchorStingsShouldBeDifferent");
      }
      return null;
    },
    disabled() {
      return !(this.envelopeId && !this.conflict);
    },
  },
  methods: {
    open() {
      this.$openModal("sendToSignatureModal");
    },
    sendToSignature() {
      this.$validator.validateAll().then((result) => {
        if (!result) return;

        this.isSending = true;
        let payload = {
          envelopeId: this.envelopeId,
          signers: this.signers,
        };

        axios
          .post("/document/signature/email", payload)
          .then((res) => {
            if (res.data.success === "sent") {
              this.$awn.success(this.$t("envelopes.successfullySent"));
              this.$refs.sendToSignatureModal.close();
            } else {
              this.$awn.warning(this.$t("envelopes.failedSent"));
            }
          })
          .finally(() => {
            this.isSending = false;
          });
      });
    },
    reset() {
      this.signers = [
        {
          name: "",
          email: "",
          anchorRequired: false,
          anchorString: "key1",
          phoneCountryCode: "+33",
        },
        {
          name: "",
          email: "",
          anchorRequired: false,
          anchorString: "key2",
          phoneCountryCode: "+33",
        },
      ];
    },
  },
};
</script>
