<template>
  <modal
    ref="sendToValidationModal"
    @open="fetchRequiredRolesForValidation"
    @close="reset"
    :action="$t('common.send')"
    :loading="isSending"
    @create="setEnvelopeValidators"
    :disabled="disabled"
    :title="$t('envelopes.validation.validatorSelectionModalTitle')"
  >
    <div class="columns">
      <div class="column">
        <div v-for="(role, idx) in roles" :key="idx">
          <many2one-field
            :label="role.name"
            :fetch="fetchUsersByRole"
            v-model="selectedUsers[idx]"
            reference="name"
            @activated="() => (selectedroleId = role.id)"
            @click="(item) => $router.push('/project/' + item.id)"
            :columns="userColumns"
            :inline="true"
            :edit="true"
            required
            :singleSelect="true"
            :hasFilter="true"
            name="role2one"
            data-vv-name="role2one"
            v-validate="{ required: true }"
            :error="errors.has('role2one')"
          ></many2one-field>
        </div>
        <div v-if="roles.length < 2">
          <hr />
          <span class="has-text-danger"
            >*{{ $t("envelopes.validation.atLeast2RolesRequired") }}</span
          >
        </div>
        <div v-if="!eachRoleSelectedAUser">
          <hr />
          <span class="has-text-danger"
            >*{{ $t("envelopes.validation.eachRoleMustSelectAUser") }}</span
          >
        </div>
      </div>
    </div>
  </modal>
</template>
<script>
import axios from "axios";
export default {
  name: "send-to-validation-modal",
  props: {
    envelopeId: [String, Number],
  },
  data() {
    return {
      roles: [],
      isSending: false,
      userColumns: {
        name: this.$t("common.name"),
        mail: "E-Mail",
        "role.name": this.$t("entities.role"),
        service: this.$t("common.service"),
      },
      selectedUsers: [],
    };
  },
  computed: {
    filteredSelectedUsers() {
      return this.selectedUsers.filter(
        (item) => item !== null && item !== undefined
      );
    },
    eachRoleSelectedAUser() {
      return (
        this.filteredSelectedUsers.length > 1 &&
        this.filteredSelectedUsers.length === this.roles.length
      );
    },
    disabled() {
      return !(
        this.envelopeId &&
        this.roles.length > 1 &&
        this.eachRoleSelectedAUser
      );
    },
  },
  methods: {
    open() {
      this.$openModal("sendToValidationModal");
    },
    fetchRequiredRolesForValidation() {
      this.$api.fetchRequiredRolesForValidation().then((data) => {
        data.forEach((role) => {
          this.roles.push({
            id: role.id,
            name: role.name,
          });
        });
      });
    },
    fetchUsersByRole(p) {
      return this.$api.fetchUsersByRole(p, this.selectedroleId);
    },
    setEnvelopeValidators() {
      if (this.disabled) return;
      this.isSending = true;
      axios
        .post("/envelope/validation/", {
          envId: this.envelopeId,
          users: this.filteredSelectedUsers,
        })
        .then(() => {
          this.$emit("created");
          this.isSending = false;
          this.$closeModal("sendToValidationModal");
        });
    },
    reset() {
      this.roles = [];
      this.selectedUsers = [];
    },
  },
};
</script>
