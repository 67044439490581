<template>
  <modal
    ref="uploadDocumentModal"
    @close="resetDocument"
    :loading="isUploading"
    @create="uploadDocument"
    :title="$t('expressions.addDocument')"
  >
    <div class="field">
      <button class="button is-primary" @click.stop="addFile">
        {{ $t("expressions.selectFile") }}
        <i class="fa fa-choose"></i>
      </button>
    </div>
    <field readonly inline :label="$t('expressions.selectedFileName')">{{
      selectedFileName
    }}</field>
    <input
      :style="{ display: 'none' }"
      ref="documentFileInput"
      type="file"
      name="documentFile"
      accept="*/*"
      cy-data="file-input"
      @change="selectFile"
    />
    <many2one-field
      :label="$t('entities.documentType')"
      v-model="document.documentType"
      :fetch="$api.fetchDocumentTypes"
      reference="name"
      :columns="documentTypeMany2OneColumns"
      :edit="true"
      cy-data="upload-type"
    ></many2one-field>
  </modal>
</template>
  <script>
import axios from "axios";
export default {
  name: "upload-document-modal",
  props: {
    envelopeId: [String, Number],
  },
  data() {
    return {
      isUploading: false,
      document: {
        documentType: null,
        file: null,
      },
      documentTypeMany2OneColumns: {
        name: this.$t("common.name"),
      },
    };
  },
  computed: {
    selectedFileName() {
      if (this.document.file) {
        return this.document.file.name;
      }
      return " ";
    },
  },
  methods: {
    open() {
      this.$openModal("uploadDocumentModal");
    },
    selectFile() {
      this.document.file = this.$refs.documentFileInput.files[0];
    },
    addFile() {
      this.$refs.documentFileInput.click();
    },
    uploadDocument() {
      const form = new FormData();
      const { file, documentType } = this.document;
      if (file === null) {
        // eslint-disable-next-line
        alert(this.$t("expressions.noFileSelected"));
        return;
      }
      if (documentType === null) {
        // eslint-disable-next-line
        alert(this.$t("expressions.noDocumentTypleSelected"));
        return;
      }
      this.isUploading = true;
      form.set("file", file, file.name);
      form.set("type", documentType.id);
      form.set("entity", this.$entitiesName.Envelope);
      form.set("id", this.envelopeId);
      axios
        .post("document", form)
        .then(() => {
          this.$closeModal("uploadDocumentModal");
        })
        .finally(() => {
          this.isUploading = false;
          this.$emit("created");
        });
    },
    resetDocument() {
      this.document = {
        file: null,
        documentType: null,
      };
    },
  },
};
</script>
