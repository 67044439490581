<template>
  <div>
    <div class="actions" v-if="transitions.length">
      <textarea
        cy-data="cy-workflow-history-comment"
        v-model="val"
        :placeholder="$t('common.comments')"
        @input="inputComment"
      ></textarea>
      <!-- The env_submit_to_validation button should not be shown in the workflow history. It involves documents uploaded to be able to shown. -->
      <button
        v-for="(transition, index) in transitions"
        v-show="transition.transitionCode !== 'env_submit_to_validation'"
        :key="index"
        class="button is-success"
        :class="{ 'is-danger': transition.transitionType === 2 }"
        @click="moveToken(transition)"
        :disabled="transition.disabled"
        :title="transition.helper || null"
        cy-data="cy-os-workflow-button"
      >
        {{ transition.name }}
      </button>
    </div>
    <div>
      <ul class="workflow-history">
        <li v-for="(token, index) in history" :key="index">
          <span
            class="dot"
            :class="{
              'dot-start': token.node.nodeType === 1,
              'dot-normal': token.node.nodeType === 2,
              'dot-error': token.node.nodeType === 3,
              'dot-end': token.node.nodeType === 4,
            }"
          ></span>
          <span class="node">{{ token.node.name }}</span>
          <div class="records">
            <span class="author"
              >{{ $t("expressions.triggeredBy") }}
              <strong>{{ token.creator && token.creator.name }}</strong>
              {{ $t("common.onDate") }} {{ token.createdAt | datetime
              }}<span v-if="token.delegatedUser">
                {{ $t("account.delegation.byDelegationOf") }}
                <strong>{{ token.delegatedUser.name }}</strong></span
              >
            </span>
            <span class="date">{{ token.createdAt | fromNow }}</span>
          </div>
          <span class="comment">{{ token.comment }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "workflow-history",
  props: {
    entity: Object,
    entityName: String,
    entityId: [String, Number],
    value: String,
    validatorsCount: Number,
  },
  data() {
    return {
      transitions: {},
      history: [],
      val: "",
    };
  },
  mounted() {
    this.val = this.value;
    this.fetchWorkflowHistory();
  },
  computed: {
    currentToken() {
      if (this.entityName === "envelope" && this.validatorsCount < 2) {
        return null;
      }
      return (this.entity && this.entity.statusWF) || null;
    },
    nodeId() {
      return (
        (this.currentToken &&
          this.currentToken.node &&
          this.currentToken.node.id) ||
        null
      );
    },
  },
  watch: {
    history() {
      if (!this.nodeId) return;
      this.fetchTransitions(this.nodeId);
    },
    nodeId() {
      if (!this.nodeId) return;
      console.log("nodeId", this.nodeId);
      this.fetchTransitions(this.nodeId);
    },
    comment(val) {
      this.val = val;
    },
    transitions() {
      console.log("this.transitions", this.transitions);
    },
  },
  methods: {
    fetchWorkflowHistory() {
      axios
        .get(`/wftoken/history/${this.entityName}/${this.entityId}`)
        .then((response) => {
          this.history = response.data;
        });
    },
    fetchTransitions(nodeId) {
      axios
        .get(`/node/${nodeId}/transitions/${this.entityId}`)
        .then((response) => {
          this.transitions = response.data;
          this.transitions.sort((a, b) => a.transitionType - b.transitionType);
        });
    },
    moveToken(transition) {
      this.$workflowHook.executePreHooks(
        transition.toNode.nodeCode,
        { entity: this.entity, comment: this.val },
        () => {
          const token = this.currentToken;
          axios
            .post(`/wftoken/move/${token.id}/${transition.id}`, {
              comment: this.val,
            })
            .then(() => {
              this.$emit("input", "");
              this.fetchWorkflowHistory();
              this.$emit("updated");
              this.$workflowHook.executePostHooks(transition.toNode.nodeCode, {
                entity: this.entity,
              });
            });
        }
      );
    },
    inputComment() {
      this.$emit("input", this.val);
    },
  },
};
</script>

<style lang='scss' scoped>
.actions {
  background-color: #eee;
  padding: 10px;

  textarea {
    width: 100%;
    margin-bottom: 10px;
    padding: 5px;
  }

  button {
    margin-right: 20px;
  }
}

.workflow-history {
  margin-left: 20px;

  > li {
    border-left: 1px solid #ccc;
    position: relative;
    padding: 20px;

    .dot {
      position: absolute;
      display: block;
      height: 20px;
      width: 20px;
      border: 1px solid #fff;
      border-radius: 100%;
      left: -10px;

      &.dot-start {
        border-color: #ffaa00;
        background-color: #ffaa00;
      }

      &.dot-normal {
        border-color: #019fc4;
        background-color: #019fc4;
      }

      &.dot-error {
        border-color: #ef413d;
        background-color: #ef413d;
      }

      &.dot-end {
        border-color: #00a65a;
        background-color: #00a65a;
      }
    }

    .records {
      display: flex;
      justify-content: space-between;

      .date {
        text-align: right;
        font-size: 14px;
      }

      .author {
        display: block;
        font-size: 14px;
      }
    }

    .node {
      font-weight: bold;
    }

    .comment {
      display: block;
      background-color: #eee;
      min-height: 30px;
    }
  }
}
</style>
