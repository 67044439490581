<template>
  <div class="is-block is-full-width">
    <div class="controls is-pulled-right" v-if="showTopButtons">
      <button
        class="button is-success white"
        @click.stop="openSendToValidationModal"
        v-if="signature"
        data-test="sel-document-send-to-validation"
      >
        {{ $t("documents.sendToValidation") }}
      </button>

      <button
        class="button is-primary white"
        @click.stop="openSendToSignatureModal"
        v-if="signature"
        data-test="sel-document-send-to-signature"
      >
        {{ $t("envelopes.sendToSignature") }}
      </button>

      <button
        class="button is-success"
        @click.stop="openDownloadContractTemplateModal"
        data-test="sel-contract-download"
        v-if="contractTemplate"
      >
        {{ $t("contractTemplates.downloadContractTemplate") }}
        <i class="fa fa-download"></i>
      </button>

      <button
        class="button is-primary white"
        @click.stop="openUploadModal"
        data-test="sel-document-upload"
      >
        {{ $t("common.upload") }}

        <i class="fa fa-upload"></i>
      </button>
    </div>
    <div class="view">
      <flexi-table
        class="is-full-width"
        :loader="fetchDocuments"
        :columns="documentTableColumns"
        :thClass="thClass"
        :showIndex="true"
        v-model="documents"
        ref="documentsTable"
        :hasActions="false"
      >
        <template slot="dataRows" slot-scope="{ item, index }">
          <tr>
            <td>{{ index + 1 }}</td>
            <td v-if="signature" style="width: 20px">
              <input type="checkbox" @input="addDocumentToSign(item.id)" />
            </td>
            <td>{{ item.filename }}</td>
            <td>{{ item.type && item.type.name }}</td>
            <td>{{ item.createdAt | reverseDate }}</td>
            <td>
              <div class="is-pulled-right">
                <!-- <form class="inline-form" method="POST" target="_blank" :action="`${API}/document/${item.id}`">
                  <button class="button is-success is-small" type="submit">
                    {{ $t('common.download') }}&nbsp;
                    <i class="fa fa-download"></i>
                  </button>
                  <input type="hidden" name="token" :value="authToken">
                </form> -->
                <!-- <button v-if="deletable" class="button is-danger is-small" @click="deleteDocument(item)">
                  <i class="fa fa-trash"></i>
                </button> -->

                <!-- <router-link :to="'/document/' + item.id" class="button is-primary is-small" v-if="viewButton">
                  <i class="fa fa-search"></i>
                </router-link> -->
              </div>
            </td>
          </tr>
        </template>
      </flexi-table>
    </div>
    <modal
      ref="createDocumentModal"
      @create="uploadDocument"
      @close="closeModal"
      :loading="isUploading"
      :title="$t('expressions.addDocument')"
    >
      <div class="field">
        <button class="button is-primary" @click.stop="addFile">
          {{ $t("expressions.selectFile") }}&nbsp;
          <i class="fa fa-choose"></i>
        </button>
      </div>
      <field readonly inline :label="$t('expressions.selectedFileName')">{{
        selectedFileName
      }}</field>
      <input
        :style="{ display: 'none' }"
        ref="documentFileInput"
        type="file"
        name="documentFile"
        accept="*/*"
        data-test="sel-document-file-input"
        @change="selectFile"
      />
      <many2one-field
        :label="$t('entities.documentType')"
        v-model="createDocument.documentType"
        :fetch="$api.fetchDocumentTypes"
        reference="name"
        :columns="documentTypeMany2OneColumns"
        :edit="true"
        data-test="sel-document-upload-type"
      ></many2one-field>
    </modal>

    <modal
      ref="downloadContractTemplateModal"
      @create="downloadContractTemplate"
      @close="closeModal"
      :title="$t('contractTemplates.downloadContractTemplate')"
    >
      <datatable
        :single-select="true"
        :size="15"
        :fetch="(p) => $api.fetchContractTemplates(p, contractType)"
        :columns="{ templateName: $t('expressions.contractName') }"
        @input="downloadContractTemplate($event)"
        selector
        class="mb-5"
      >
      </datatable>
      <div slot="toolbar"></div>
    </modal>

    <modal
      ref="sendToValidationModal"
      :action="$t('common.send')"
      :loading="isSending"
      @create="setDocumentsValidators"
      @close="closeModal"
      :title="$t('envelopes.validatorSelectionModalTitle')"
    >
      <div class="columns">
        <div class="column">
          <div
            v-for="(role, idx) in documentValidationRequiredRoles"
            :key="idx"
          >
            <many2one-field
              :label="role.name"
              :fetch="fetchUsersByRole"
              v-model="selectedUsers[idx]"
              reference="name"
              @activated="() => (selectedroleId = role.id)"
              @click="(item) => $router.push('/project/' + item.id)"
              :columns="userColumns"
              :inline="true"
              :edit="true"
              required
              :singleSelect="true"
              :hasFilter="true"
              name="role2one"
              data-vv-name="role2one"
              v-validate="{ required: true }"
              :error="errors.has('role2one')"
            ></many2one-field>
          </div>
        </div>
      </div>
    </modal>

    <modal
      ref="sendToSignatureModal"
      @create="sendToSignature"
      @close="closeModal"
      :action="$t('common.send')"
      :loading="isSending"
      :title="$t('envelopes.signersModalTitle')"
    >
      <div class="columns">
        <div class="column is-half">
          <h3 class="subtitle is-3">{{ $t("envelopes.axioneSide") }}</h3>
          <text-field
            :label="$t('common.name')"
            v-model="signers[0].name"
            :inline="false"
            :edit="true"
            required
          >
          </text-field>
          <text-field
            :label="$t('common.email')"
            v-model="signers[0].email"
            :inline="false"
            :edit="true"
            required
          >
          </text-field>
          <text-field
            label="Phone Country Code"
            v-model="signers[0].phoneCountryCode"
            :inline="false"
            :edit="true"
            required
          >
          </text-field>
          <text-field
            :label="$t('common.phone')"
            v-model="signers[0].phoneNumber"
            :inline="false"
            :edit="true"
            required
          >
          </text-field>
          <h4 class="subtitle is-4">
            <span>{{ $t("envelopes.signaturePosition") }}</span>
            <span class="help" :title="$t('envelopes.positionHelper')">?</span>
          </h4>
          <div class="tabs is-toggle">
            <ul>
              <li
                :class="{ 'is-active': !signers[0].anchorRequired }"
                @click="signers[0].anchorRequired = false"
              >
                <a><span>Manuel</span></a>
              </li>
              <li
                :class="{ 'is-active': signers[0].anchorRequired }"
                @click="signers[0].anchorRequired = true"
              >
                <a><span>Automatique</span></a>
              </li>
            </ul>
          </div>
          <text-field
            :label="$t('envelopes.anchorString')"
            v-model="signers[0].anchorString"
            v-if="signers[0].anchorRequired"
            :inline="false"
            :required="signers[0].anchorRequired"
            :edit="true"
          >
          </text-field>
        </div>
        <div class="column is-half">
          <h3 class="subtitle is-3">{{ $t("envelopes.contractorSide") }}</h3>
          <text-field
            :label="$t('common.name')"
            v-model="signers[1].name"
            :inline="false"
            :edit="true"
            required
          >
          </text-field>
          <text-field
            :label="$t('common.email')"
            v-model="signers[1].email"
            :inline="false"
            :edit="true"
            required
          >
          </text-field>
          <text-field
            label="Phone Country Code"
            v-model="signers[1].phoneCountryCode"
            :inline="false"
            :edit="true"
            required
          >
          </text-field>
          <text-field
            :label="$t('common.phone')"
            v-model="signers[1].phoneNumber"
            :inline="false"
            :edit="true"
            required
          >
          </text-field>
          <h4 class="subtitle is-4">
            <span>{{ $t("envelopes.signaturePosition") }}</span>
            <span class="help" :title="$t('envelopes.positionHelper')">?</span>
          </h4>
          <div class="tabs is-toggle">
            <ul>
              <li
                :class="{ 'is-active': !signers[1].anchorRequired }"
                @click="signers[1].anchorRequired = false"
              >
                <a><span>Manuel</span></a>
              </li>
              <li
                :class="{ 'is-active': signers[1].anchorRequired }"
                @click="signers[1].anchorRequired = true"
              >
                <a><span>Automatique</span></a>
              </li>
            </ul>
          </div>
          <text-field
            :label="$t('envelopes.anchorString')"
            v-if="signers[1].anchorRequired"
            v-model="signers[1].anchorString"
            :inline="false"
            :required="signers[1].anchorRequired"
            :edit="true"
          >
          </text-field>
        </div>
      </div>
    </modal>

    <form
      class="is-hidden"
      method="POST"
      target="_blank"
      :action="`${API}/contracttemplate/${documentId}`"
      ref="downloadForm"
    >
      <input type="hidden" name="token" :value="authToken" />
    </form>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "document-manager",
  props: {
    objectName: String,
    // eslint-disable-next-line
    objectId: String | Number,
    deletable: {
      type: Boolean,
      default: false,
    },
    showTopButtons: {
      type: Boolean,
      default: true,
    },
    thClass: {
      type: String,
      default: "",
    },
    // Can download a contract template
    contractTemplate: {
      type: Boolean,
      default: false,
    },
    contractType: {
      type: String,
      default: null,
    },
    // Can send documents for signature
    signature: {
      type: Boolean,
      default: false,
    },
    viewButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      documents: [],
      documentValidationRequiredRoles: [],
      selectedUsers: [],
      selectedroleId: 0,
      userColumns: {
        name: this.$t("common.name"),
        mail: "E-Mail",
        "role.name": this.$t("entities.role"),
        service: this.$t("common.service"),
      },
      createDocument: {
        documentType: null,
        file: null,
      },
      API: axios.defaults.baseURL,
      authToken: this.$store.getters["auth/getToken"],
      documentTypeMany2OneColumns: {
        name: this.$t("common.name"),
      },
      isUploading: false,
      isLoading: false,
      documentId: null,
      documentsToSign: {},
      signers: [
        {
          name: "",
          email: "",
          anchorRequired: false,
          anchorString: "key1",
          phoneCountryCode: "+33",
        },
        {
          name: "",
          email: "",
          anchorRequired: false,
          anchorString: "key2",
          phoneCountryCode: "+33",
        },
      ],
      isSending: false,
    };
  },
  computed: {
    ...mapGetters({
      getRights: "auth/getRights",
    }),
    selectedFileName() {
      if (this.createDocument.file) {
        return this.createDocument.file.name;
      }
      return " ";
    },
    documentTableColumns() {
      let columns = [];
      if (this.signature) {
        columns.push({
          title: "",
          name: "checkbox",
        });
      }
      const additional = [
        {
          title: this.$t("expressions.fileName"),
          name: "filename",
          accessor: "filename",
          filter: "",
        },
        {
          title: this.$t("common.type"),
          name: "type",
          accessor: "type.name",
          filter: "",
        },
        {
          title: this.$t("common.date"),
          name: "date",
          accessor: "createdAt",
          filter: "",
        },
      ];
      columns.push(...additional);

      return columns;
    },
  },
  mounted() {
    this.fetchRequiredRolesForValidation();
  },
  methods: {
    fetchRequiredRolesForValidation() {
      this.$api.fetchRequiredRolesForValidation().then((data) => {
        data.forEach((role) => {
          this.documentValidationRequiredRoles.push({
            id: role.id,
            name: role.name,
          });
        });
        // this.documentValidationRequiredRoles = data;
      });
    },
    fetchDocuments(callback) {
      console.log("fetchDocuments for objectId", this.objectId);
      axios
        .get(`/documents?entity=${this.objectName}&id=${this.objectId}`)
        .then((res) => {
          if (typeof callback === "function") {
            callback(res.data);
          }
        });
    },
    uploadDocument() {
      const form = new FormData();
      const { documentType, file } = this.createDocument;
      if (documentType === null || file === null) {
        // eslint-disable-next-line
        alert(this.$t("expressions.noFileSelected"));
        return;
      }
      this.isUploading = true;
      form.set("file", this.createDocument.file, this.createDocument.file.name);
      form.set("entity", this.objectName);
      form.set("id", this.objectId);
      form.set("type", this.createDocument.documentType.id);
      axios
        .post("document", form)
        .then(() => {
          this.$refs.createDocumentModal.close();
        })
        .finally(() => {
          this.isUploading = false;
          this.$refs.documentsTable.fetch();
        });
    },
    openUploadModal() {
      this.$refs.createDocumentModal.open();
    },
    openDownloadContractTemplateModal() {
      this.$refs.downloadContractTemplateModal.open();
    },
    downloadContractTemplate(event) {
      this.documentId = event.id;
      this.$nextTick(() => {
        this.$refs.downloadForm.submit();
      });
      this.$refs.downloadContractTemplateModal.close();
    },
    addFile() {
      this.$refs.documentFileInput.click();
    },
    selectFile() {
      this.createDocument.file = this.$refs.documentFileInput.files[0];
    },
    closeModal() {
      this.createDocument = {
        documentType: null,
        file: null,
      };
      this.selectedUsers = [];
      this.selectedroleId = -1;
    },
    deleteDocument(document) {
      return this.$awn.confirm(this.$t("expressions.deleteDocument"), () =>
        axios.delete(`/document/${document.id}`).then((response) => {
          if (response.data.success) {
            this.$refs.documentsTable.fetch();
          }
        })
      );
    },
    addDocumentToSign(documentId) {
      if (this.documentsToSign[documentId]) {
        delete this.documentsToSign[documentId];
        return;
      }
      this.documentsToSign[documentId] = true;
    },
    openSendToSignatureModal() {
      if (Object.keys(this.documentsToSign).length === 0) {
        this.$awn.warning(this.$t("expressions.noDocumentSelected"));
        return;
      }
      this.$refs.sendToSignatureModal.open();
    },
    openSendToValidationModal() {
      if (Object.keys(this.documentsToSign).length === 0) {
        this.$awn.warning(this.$t("expressions.noDocumentSelected"));
        return;
      }
      this.$refs.sendToValidationModal.open();
    },
    fetchUsersByRole(p) {
      return this.$api.fetchUsersByRole(p, this.selectedroleId);
    },
    sendToSignature() {
      this.isSending = true;
      this.$validator.validateAll().then((result) => {
        if (!result) {
          this.isSending = false;
          return;
        }

        // Anchor strings should not be the same
        if (
          this.signers[0].anchorRequired &&
          this.signers[1].anchorRequired &&
          this.signers[0].anchorString === this.signers[1].anchorString
        ) {
          this.$awn.warning(
            this.$t("envelopes.signature.anchorStingsShouldBeDifferent")
          );
          this.isSending = false;
          return;
        }

        let payload = {
          docIds: Object.keys(this.documentsToSign),
          signers: this.signers,
        };

        axios
          .post("/document/signature/email", payload)
          .then((res) => {
            if (res.data.success === "sent") {
              this.$awn.success(this.$t("envelopes.successfullySent"));
              this.$refs.sendToSignatureModal.close();
            } else {
              this.$awn.warning(this.$t("envelopes.failedSent"));
            }
          })
          .finally(() => {
            this.isSending = false;
          });
      });
    },
    setDocumentsValidators() {
      axios
        .post("/document/validation/", {
          docIds: Object.keys(this.documentsToSign),
          users: this.selectedUsers,
        })
        .then(() => {
          this.$refs.sendToValidationModal.close();
        })
        .finally(() => {
          this.isSending = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.controls .button {
  margin-left: 4px;
}

.is-full-width {
  width: 100%;
  flex: 1;
}

.white {
  color: #fff;
}

.field {
  margin-bottom: 0.75em;
}

.inline-form {
  display: inline;
}

td .is-pulled-right > * {
  margin-left: 4px;
}

.help {
  display: inline-block;
  color: white;
  background-color: #019fc4;
  border-radius: 50%;
  padding: 0 5px;
  cursor: pointer;
  margin-left: 5px;
}
</style>
