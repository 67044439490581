<template>
  <div class="is-block is-full-width">
    <div class="level-right envelopes-actions">
      <button
        class="button is-success"
        v-if="getRights($entitiesName.Envelope).create"
        @click.stop="$refs.addEnvelopeModal.open"
      >
        <i class="fa fa-plus"></i>
      </button>
      <button
        class="button is-primary"
        @click.stop="$refs.downloadContractTemplateModal.open()"
        cy-data="download-contract-template"
      >
        {{ $t("contractTemplates.downloadContractTemplate") }}
        <i class="fa fa-download"></i>
      </button>
    </div>
    <hr />
    <!-- 3 columns on page -->
    <div
      v-if="envelopes.length > 0"
      class="columns"
      :key="'envelope_' + envelopeId"
    >
      <!-- 1st column, envelope menu  -->
      <aside class="menu column is-narrow">
        <ul
          :key="'menu_' + idx"
          class="menu-list"
          v-for="(envelope, idx) in envelopes"
        >
          <li @click="onClickMenu(idx)">
            <a :class="envelope.id === envelopeId ? 'selected' : ''">{{
              envelope.name
            }}</a>
          </li>
        </ul>
      </aside>
      <!-- 2nd column, envelope content, documents, and validators  -->
      <div class="column left">
        <div class="basic-info" :key="'basinf_' + envelopeId">
          <text-field
            :label="$t('common.name')"
            :value="envelope.name"
            :readonly="true"
          />
          <date-field
            :label="$t('common.date')"
            :value="envelope.createdAt"
            :readonly="true"
          />
        </div>
        <br />
        <documents-table
          v-if="envelopeId"
          ref="doctab"
          :key="'doctab_' + documentsTableKey"
          :objectName="$entitiesName.Envelope"
          :objectId="envelopeId"
          :deletable="
            getRights($entitiesName.Envelope).update && envelopeIsDraft
          "
          :showTopButtons="false"
          th-class="has-background-light"
          @updated="updateDocumentsCount"
        />
        <br />
        <validators-table
          v-if="envelopeId"
          :envelopeId="envelopeId"
          :key="'valitab_' + validatorsTableKey"
          @updated="updateValidatorsCount"
        ></validators-table>
      </div>
      <!-- 3rd column, current envelope action buttons, and workflow history  -->
      <div class="column right">
        <div class="level-right envelope-actions">
          <button
            class="button is-success white"
            v-if="signature && documentsCount > 0 && envelopeIsDraft"
            @click.stop="$refs.sendToValidationModal.open"
            cy-data="send-to-validation"
          >
            {{ $t("envelopes.validation.sendToValidation") }}
          </button>
          <button
            class="button is-primary white"
            v-if="signature && envelopeIsValidated"
            @click.stop="$refs.sendToSignatureModal.open"
            cy-data="send-to-signature"
          >
            {{ $t("envelopes.signature.sendForSignature") }}
          </button>
          <button
            class="button is-primary white"
            v-if="signature && envelopeIsDraft"
            @click.stop="$refs.uploadDocumentModal.open"
            cy-data="upload"
          >
            {{ $t("common.upload") }}
            <i class="fa fa-upload"></i>
          </button>
          <button
            v-if="getRights($entitiesName.Contract).update"
            class="button is-danger"
            @click="deleteEnvelope"
          >
            <i class="fa fa-trash"></i>
          </button>
        </div>
        <workflow-history
          v-if="envelopeId"
          :key="'workhis_' + workflowHistoryKey"
          v-model="workflowComment"
          :entity="envelope"
          :entityName="WorkflowCodes.ENV.NODE.OBJECT_NAME"
          :entityId="envelopeId"
          :validators-count="validatorsCount"
          @updated="refreshValidatorsTableAndStatusWF"
        ></workflow-history>
      </div>
    </div>

    <!-- modals  -->
    <download-contract-template-modal ref="downloadContractTemplateModal">
    </download-contract-template-modal>
    <add-envelope-modal
      ref="addEnvelopeModal"
      :contract-id="contractId"
      @created="refreshPage"
    >
    </add-envelope-modal>
    <upload-document-modal
      ref="uploadDocumentModal"
      :envelopeId="envelopeId"
      @created="refreshDocumentsTable"
    ></upload-document-modal>
    <send-to-validation-modal
      ref="sendToValidationModal"
      :envelopeId="envelopeId"
      @created="refreshValidatorsTableAndStatusWF"
    ></send-to-validation-modal>
    <send-to-signature-modal
      ref="sendToSignatureModal"
      :envelopeId="envelopeId"
      @created="fetchEnvelopeWorkflowToken"
    ></send-to-signature-modal>
  </div>
</template>
<style scoped>
.menu {
  border-right: 1px solid lightgray;
}

.menu .selected {
  color: white;
  background-color: #f67a15;
}

.level-right .button {
  margin-left: 4px;
}

.envelope-actions .button {
  margin-left: 4px;
}
</style>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { Workflows as WORKFLOWS } from "../constants/Workflow";

export default {
  name: "envelopes-manager",
  props: ["contractId", "signature"],
  data() {
    return {
      envelopes: [],
      envelopeId: null,
      envelope: {},
      documentsCount: 0,
      workflowComment: "",
      WorkflowCodes: WORKFLOWS,
      documentsTableKey: 0,
      workflowHistoryKey: 0,
      validatorsTableKey: 0,
      envelopeActionsKey: 0,
      validatorsCount: 0,
    };
  },
  computed: {
    ...mapGetters({
      getRights: "auth/getRights",
    }),
    envelopeIsDraft() {
      return (
        this.envelope.statusWF &&
        this.envelope.statusWF.node.nodeCode ===
          this.WorkflowCodes.ENV.NODE.DRAFT
      );
    },
    envelopeIsValidated() {
      return (
        this.envelope.statusWF &&
        this.envelope.statusWF.node.nodeCode ===
          this.WorkflowCodes.ENV.NODE.VALIDATED
      );
    },
  },
  mounted() {
    this.fetchData();
    this.envelopeId = this.$route.query.envelopeId;
    if (this.envelopeId) {
      this.envelopeId = parseInt(this.envelopeId, 10);
      this.switchEnvelope();
    }
  },
  methods: {
    async fetchEnvelopes() {
      const res = await axios.get(`contract/${this.contractId}/envelopes`);
      this.envelopes = res.data;
    },
    // this api exists but method is not used
    async fetchEnvelopeById() {
      const res = await axios.get(`/envelope/${this.envelopeId}`);
      this.envelope = res.data;
    },
    setFirstEnvelopeAsDefault() {
      if (this.envelopes.length === 0 || this.envelopeId) return;
      this.envelope = this.envelopes[0];
      this.envelopeId = this.envelope.id;
      this.fetchEnvelopeWorkflowToken();
    },
    async fetchData() {
      await this.fetchEnvelopes();
      this.setFirstEnvelopeAsDefault();
    },
    setEnvelopeById() {
      if (this.envelopes.length === 0 || !this.envelopeId) return;
      this.envelope = this.envelopes.find(
        (item) => item.id === this.envelopeId
      );
    },
    async switchEnvelope() {
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          envelopeId: this.envelopeId,
        },
      });
      await this.fetchEnvelopes();
      this.setEnvelopeById();
      this.updateDocumentsCount(0);
      this.fetchEnvelopeWorkflowToken();
    },
    async fetchEnvelopeWorkflowToken() {
      const res = await axios.get(`/wftoken/envelope/${this.envelopeId}`);
      if (!(res && res.data)) return;
      this.$set(this.envelope, "statusWF", res.data);
      this.refreshWorkflowHistory();
    },
    onClickMenu(idx) {
      this.envelope = this.envelopes[idx];
      this.envelopeId = this.envelope.id;
      this.switchEnvelope();
    },
    resetMenu() {
      this.envelopeId = null;
    },
    refreshPage(val) {
      this.envelopeId = val;
      this.switchEnvelope();
    },
    async deleteEnvelope() {
      if (!this.envelopeId) return;
      this.$awn.confirm(this.$t("expressions.deleteEnvelope"), () => {
        axios.delete(`/envelope/${this.envelopeId}`).then(() => {
          this.resetMenu();
          this.fetchData();
        });
      });
    },
    refreshDocumentsTable() {
      this.documentsTableKey += 1;
    },
    refreshValidatorsTable() {
      this.validatorsTableKey += 1;
    },
    refreshWorkflowHistory() {
      this.workflowHistoryKey += 1;
    },
    refreshValidatorsTableAndStatusWF() {
      this.refreshValidatorsTable();
      this.fetchEnvelopeWorkflowToken();
    },
    updateDocumentsCount(v) {
      this.documentsCount = v;
    },
    updateValidatorsCount(v) {
      this.validatorsCount = v;
    },
  },
};
</script>
