<template>
  <modal
    ref="addEnvelopeModal"
    @create="addEnvelope"
    @close="reset"
    :title="$t('envelopes.addEnvelope')"
  >
    <div class="columns">
      <div class="column is-half">
        <text-field
          :label="$t('common.name')"
          v-model="newEnvelope.name"
          :edit="true"
          :inline="false"
        />
      </div>
    </div>
  </modal>
</template>
<script>
import axios from "axios";
export default {
  name: "add-envelope-modal",
  inject: ["$validator"],
  props: {
    contractId: [String, Number],
  },
  data() {
    return {
      newEnvelope: {
        name: null,
      },
      newEnvelopeId: null,
    };
  },
  computed: {},
  methods: {
    open() {
      this.$openModal("addEnvelopeModal");
    },
    close() {
      this.$closeModal("addEnvelopeModal");
    },
    addEnvelope() {
      // todo: seems not validating name field
      this.$validator.validateAll().then((res) => {
        if (res) {
          const envelope = {
            ...this.newEnvelope,
            contractId: this.contractId,
          };
          axios.post(`/envelope`, envelope).then((res) => {
            this.newEnvelopeId = res.data.id;
            this.$emit("created", this.newEnvelopeId);
            this.close();
          });
        }
      });
    },
    reset() {
      this.newEnvelope.name = null;
    },
  },
};
</script>
